const header = {
    homepage: 'https://amberlai1.github.io',
    title: 'Home',
  }
  
  const about = {
    name: 'Amber Lai',
    role: 'Software Developer',
    description:
      'Hi! My name is Amber, and I am a student at the University of Rochester majoring in Computer Science. I am interested in software development, especially full stack web development. In my free time, I love to travel and run a food instagram!',
    resume: 'https://copper-charlotta-51.tiiny.site',
    social: {
      linkedin: 'https://www.linkedin.com/in/amberlai/',
      github: 'https://github.com/amberlai1',
      instagram: 'https://www.instagram.com/ambieeating/'
    },
  }
  
  const projects = [
    {
      name: 'Clear',
      description:
        'Create a self prioritizing to do list app using Flutter and Dart that won first place in the University of Rochester 2022 Hackathon Dandy Hacks',
      stack: ['Flutter', 'Dart', 'Python'],
      sourceCode: 'https://devpost.com/software/clear-ax0jrd',
      livePreview: 'https://devpost.com/software/clear-ax0jrd',
    },
    {
      name: 'Video Game Recommendation System',
      description:
        'Created a Steam video game recommendation system based on different playtimes from various users and genres using two different datasets with the Cosine Similarity and Singular Value Decomposition algorithms',
      stack: ['Python', 'Juypter Notebook'],
      sourceCode: 'https://github.com/amberlai1/Video-Game-Recommendation-System',
      livePreview: 'https://github.com/amberlai1/Video-Game-Recommendation-System',
    },
    {
      name: 'Restaurant Picker',
      description:
        'If you are ever indecisive about what to eat, check out this restaurant picker that allows you to put in what type of cuisine and city and will generate a place to eat!',
      stack: ['Python'],
      sourceCode: 'https://github.com/amberlai1/RestaurantPickerYelp',
      livePreview: 'https://github.com/amberlai1/RestaurantPickerYelp',
    },
  ]
  
  const skills = [
    'Java',
    'Python',
    'C',
    'HTML5',
    'CSS3',
    'JavaScript',
    'React',
    'Flask',
    'Flutter',
    'Dart',
    'Git/Github',
    'R/RStudio'
  ]
  
  const contact = {
    email: 'alai8@u.rochester.edu',
  }
  
  export { header, about, projects, skills, contact }